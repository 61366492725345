<template>
  <div class="home">
    <div id="stiDashboardsContainer" style="width: 100%;" class="stiReportsContainer">
      <h5>Dashboard</h5>
    </div>
  </div>
</template>

<script>

import { store } from '@/store/store.js'

export default {
  name: 'home',
  store,
  components: {

  },
  data () {
    return {
      viewer : null
    }
  },
  created(){
    const self = this;
    self.viewer = document.getElementById("stiDashboardsContainer");

  },
  mounted () {
    const self = this;

  },
  methods:{

  }
}
</script>
